<template>
  <div>
    <Newheader></Newheader>
    <div class="flex-col page">
      <div class="flex-col">
        <div data-aos="fade-up" class="flex-col section" >

          <!-- <video id="index_video" class="image" -->
          <!-- src="@/assets/video/zd/1.mp4" -->
          <!-- style="object-fit: cover; width: 100%; height: 100%;position: absolute;left: 0;" x5-playsinline="true" webkit-playsinline="true" -->
          <!-- playsinline="true" x5-video-player-type="h5" x5-video-player-fullscreen="true" x5-video-ignore-metadata="true" -->
          <!-- autoplay="" muted="" loop=""></video> -->
          
          <!--  -->
          <!--  -->
          <div class="flex-col items-center space-y-5 section_2" style="z-index:10;margin-top: 140px;">
              <div style="text-align:center;"><img data-aos="fade-up" src="../assets/images/b3.png" style="width:420px;height:135px;"/></div>
            <div><img data-aos="fade-up" src="../assets/images/b2.png" style="width:1300px;height:200px;"/></div>
            <div class="flex-col space-y-30 group">
              <span class="font_1 text" style="font-family: Albbr; text-align: center;">光阳电动BaaS电池租用服务</span>
              <div class="group_2">
                <span class="font_2" style="font-family: Albbr;">
                  轻便骑行，城市代步，适合日常通勤骑行需求
                  <br />
                </span>
                <span class="font_2" style="font-family: Albbr;">每一次通勤能量加满，上班路上更省心！</span>
              </div>
            </div>
            <div>
              <div @click="play('https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2022/images/2.9c7a3f56.mp4')" class="flex-row justify-center space-x-18 section_3" style="cursor: pointer;text-align: center;position: relative; top:20px"> 
              <img class="image" 
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16618279462540644809.png" /> 
              <span class="text_2" syle="font-family: Albbr;">观看完整影片</span> 
            </div>
            </div>
          </div>
        </div>
        <div data-aos="fade-up" class="flex-col space-y-26 section_4">
          <span class="font_1 text_3">开启电动出行的新纪元</span>
          <div class="flex-col">
            <div class="group_3">
              <span class="font_3" style="font-family: Albbr;">电摩拥车新方式</span>
              <span class="text_4" style="font-family: Albbr;">可租、可换、可升级</span>
            </div>
            <div class="flex-col group_20">
               <div class="group_20_1">
                   <div class="flex-col group_20_2">
                    <div class="flex-row" style="align-items:end;">
                       <span class="font1">可租</span>
                       <span class="font2">轻松购，选租电</span>
                    </div>
                    <div class="flex-col" style="margin-top:23px;">
                         <span class="font3">采用租电方式使用锂电池，</span>
                         <span class="font3">让用户用充足的预算价格购买更高质量的电车！</span>
                         <span class="font3">为用户带来更实用便捷的电动出行新选择！</span>
                         <span class="font3">电池用租的，省钱又安心！</span>
                    </div>
                   </div>
               </div>
               <div class="group_20_1_1">
                   <div class="flex-col group_20_2">
                    <div class="flex-row" style="align-items:end;" >
                       <span class="font1">可换</span>
                       <span class="font2">轻松租，不衰减</span>
                    </div>
                    <div class="flex-col" style="margin-top:23px;">
                         <span class="font3">锂电池衰减不可避免，租电让你使用锂电池的</span>
                         <span class="font3">过程中无后顾之忧！基于ionex车能网的技术支持，</span>
                         <span class="font3">光阳电动进行下一步的换电布局！</span>
                         <span class="font3">电池用租的，锂电不衰减!</span>
                    </div>
                   </div>
               </div>
               <div class="group_20_1_2">
                   <div class="flex-col group_20_2">
                    <div class="flex-row " style="align-items:end;">
                       <span class="font1">可升级</span>
                       <span class="font2">轻松享，动力升级</span>
                    </div>
                    <div class="flex-col" style="margin-top:23px;">
                         <span class="font3">在锂电技术性能不断迭代的今天，</span>
                         <span class="font3">使用租电的方式，</span>
                         <span class="font3">便可以第一时间享受更强的锂电动力！</span>
                         <span class="font3">电池用租的，升级更省心！</span>
                    </div>
                   </div>
               </div>
            </div>
            <div class="group_21">
                <div class="text_11">电池服务资费方案</div>
                  <div class="text_12">选择BaaS,用户将不必购买电池,按需租用特定电池包</div>
                  <div style="width: 120rem; margin: 0 auto;">更新日期：2022/11/20</div>
                  <div style="width: 120rem; margin: 0 auto;">
                    <div class="group_22">
                      <div class="text_13">惠生活系列</div>
                      <div class="group_23">
                         <div class="text_14">轻便骑行，城市代步。</div>
                         <div class="text_15">适合日常通勤骑行需求，每一次通勤能量加满，上班路上更省心！</div>
                      </div>
                    </div>
                      <img class="img2" src="../assets/images/zz1.png"/>
                  </div>
                  <div style="width: 120rem; margin: 0 auto;"  v-if="show" >
                    <div class="group_22">
                      <div class="text_13">自在骑系列</div>
                      <div class="group_23">
                         <div class="text_14">乐享出行，畅快骑行。</div>
                         <div class="text_15">开启轻松自在的骑行生活，进阶车规级锂电池，享更强安全动力出行</div>
                      </div>
                    </div>
                      <img class="img2" src="../assets/images/zz2.png"/>
                  </div>
                  <div style="width: 120rem; margin: 0 auto;" v-if="show">
                    <div class="group_22">
                      <div class="text_13">探路者系列</div>
                      <div class="group_23">
                         <div class="text_14">一骑绝乘，性能专享</div>
                         <div class="text_15">高性能电池，高电压平台，让你骑的更快、更远、更安全！</div>
                      </div>
                    </div>
                      <img class="img2" src="../assets/images/zz3.png"/>
                  </div>
                  <div class="group_24" v-if="isshow">
                    <span class="text_16" @click="ck">查看更多租电方案</span>
                  </div>
                  <div style="text-align:center">
                    <img class="img2" src="../assets/images/zz5.png"
                  />
                  </div>
            </div>
            <div class="group_25"> 
              <div class="group_26">
                <div class="group_27">
                  <img class="img3" src="../assets/images/cn.png"/>
                </div>
                  <span class="text_17">『Ionex车能网』智能安全出行生态系统</span>
                  <span class="text_18">全面开创两轮智能出行新体验，是全球电动车市场最佳智能化解决方案</span>
                  <div class="group_28">
                    <span class="text_19" @click="navPath('/ionex')" >了解车能网</span>
                  </div>
              </div>
            </div> 
          </div>
        </div>
      </div>
    </div>
    <newfooter />
  </div>
</template>

<script>
export default {
  name: 'Vue3Zd',

  data() {
    return {
        show:false,
        isshow:true,
    };
  },

  mounted() {

  },

  methods: {
     ck(){
      this.show=true,
      this.isshow=false
     }
  },
};
</script>

<style scoped>
.page {
  
  background-color: #000000;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.section {
  position: relative;
  height: 100vh;
  background-image: url('../assets/images/b1.png');
  background-size: 100% 100%;
}

.section_2 {
  padding-bottom: 108px;
  height: 100vh;
}

.space-y-5>*:not(:first-child) {
  margin-top: 50px;
}

.group {
  padding: 10 0;
  
}

.font_1 {
  font-size: 36px;
  font-family: 'Albb';
  line-height: 46px;
  color: #ffffff;
  margin-top: 20px;
}

.text {
  margin-left: 21px;
  line-height: 45px;
  letter-spacing: 2.4px;
}

.group_2 {
  text-align: center;
}

.font_2 {
  font-size: 20px;
  font-family: AlibabaPuHuiTiM;
  letter-spacing: 1.19px;
  line-height: 30px;
  color: #ffffff;
}

.section_3 {
  padding: 20px 44px 20px 50px;
  background-color: #ffffff;
  border-radius: 35px;
  width: 300px;
  position: relative;
  top: -150px;
}

.space-x-18>*:not(:first-child) {
  margin-left: 18px;
}

.image {
  width: 30px;
  height: 30px;
}

.text_2 {
  margin-bottom: 3px;
  color: #000000;
  font-size: 25px;
  font-family: AlibabaPuHuiTiM;
  line-height: 25px;
  letter-spacing: 1.25px;
}

.section_4 {
  padding-top: 149px;
  background-color:#010023 ;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.space-y-26>*:not(:first-child) {
  margin-top: 26px;
}

.text_3 {
  align-self: center;
}

.group_3 {
  align-self: center;
  line-height: 27px;
}
.group_20 {
  padding-top: 60px;
  padding-bottom: 245px;
  text-align: center;
  margin: 0 auto;
}
.group_20_1{
  width: 1200px;
  height: 400px;
  background-image: url('../assets/images/zb1.png');
  background-size: 100% 100%;
  position: relative;
}
.group_20_1_1{
  width: 1200px;
  height: 400px;
  background-image: url('../assets/images/zb2.png');
  background-size: 100% 100%;
  position: relative;
  margin-top: 36px;
}
.group_20_1_2{
  width: 1200px;
  height: 400px;
  background-image: url('../assets/images/zb3.png');
  background-size: 100% 100%;
  position: relative;
  margin-top: 36px;
}
.group_20_2{
  position: absolute;
  top:90px;
  left:690px;
}
.font1{
  font-size: 36px;
  color: #3100ff;
  font-family: Albb;
}
.font2{
  margin-left: 36px;
  font-size: 22px;
  font-style: italic;
  font-family: Albb;
}
.font3{
  font-family: Albbr;
  font-size: 20px;
  text-align: start;
  margin-top:13px;
}
.font_3 {
  font-size: 28px;
  font-family: AlibabaPuHuiTiR;
  line-height: 27px;
  color: #fefefe;
}

.text_4 {
  color: #0084ff;
  font-size: 28px;
  font-family: Alibaba PuHuiTi;
  font-weight: 700;
  line-height: 27px;
}

.section_5 {
  padding: 68px 211px 53px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16654537815648366664.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.space-y-15>*:not(:first-child) {
  margin-top: 15px;
}

.space-x-148>*:not(:first-child) {
  margin-left: 148px;
}

.group_4 {
  flex: 1 1 400px;
}

.space-y-30>*:not(:first-child) {
  margin-top: 30px;
}

.image_2 {
  width: 400px;
  height: 540px;
  object-fit: cover;
}

.font_4 {
  font-size: 28px;
  font-family: 'Albb';
  line-height: 26px;
  color: #0084ff;
}

.font_5 {
  font-size: 18px;
  font-family: AlibabaPuHuiTiR;
  line-height: 17px;
  color: #ffffff;
}

.text_7 {
  margin-top: 8px;
}

.group_5 {
  flex: 1 1 400px;
}

.image_3 {
  width: 400px;
  height: 430px;
}

.group_7 {
  margin-top: 30px;
}

.space-x-22>*:not(:first-child) {
  margin-left: 22px;
}

.text_5 {
  margin-top: 7px;
  line-height: 18px;
}

.group_8 {
  margin-top: 17px;
  line-height: 24px;
}

.font_6 {
  font-size: 14px;
  font-family: AlibabaPuHuiTiR;
  line-height: 24px;
  color: #ffffff;
}

.group_6 {
  flex: 1 1 400px;
}

.space-y-28>*:not(:first-child) {
  margin-top: 28px;
}

.space-x-26>*:not(:first-child) {
  margin-left: 26px;
}

.text_6 {
  margin-top: 8px;
}

.group_9 {
  align-self: flex-start;
  width: 1332px;
}

.group_11 {
  margin-top: 3px;
  line-height: 24px;
  height: 96px;
}

.group_10 {
  line-height: 24px;
  height: 97px;
}

.group_12 {
  margin-top: 108px;
  align-self: center;
}

.space-y-24>*:not(:first-child) {
  margin-top: 24px;
}

.group_13 {
  line-height: 29px;
}

.text_8 {
  line-height: 28px;
}

.text_9 {
  color: #ffffff;
  font-size: 30px;
  font-family: PingFangSC;
  line-height: 29px;
}

.group_14 {
  margin-top: 80px;
  align-self: center;
}

.space-y-58>*:not(:first-child) {
  margin-top: 58px;
}

.space-x-69>*:not(:first-child) {
  margin-left: 69px;
}

.image_5 {
  margin: 6px 0 3px;
  flex-shrink: 0;
  width: 364px;
  height: 217px;
}

.image_4 {
  flex: 1 1 auto;
  width: 767px;
  height: 226px;
}

.space-x-50>*:not(:first-child) {
  margin-left: 50px;
}

.image_6 {
  margin-top: 9px;
  flex-shrink: 0;
  width: 383px;
  height: 217px;
}

.group_15 {
  margin-top: 57px;
  align-self: center;
}

.space-x-133>*:not(:first-child) {
  margin-left: 133px;
}

.image_7 {
  margin-top: 10px;
  flex-shrink: 0;
  width: 300px;
  height: 216px;
}

.group_16 {
  margin-top: 58px;
}

.space-y-188>*:not(:first-child) {
  margin-top: 188px;
}

.image_8 {
  align-self: center;
  width: 1200px;
  height: 445px;
}

.section_6 {
  padding: 103px 0 108px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16654537798270214489.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.space-y-33>*:not(:first-child) {
  margin-top: 33px;
}

.image_9 {
  width: 563px;
  height: 52px;
}

.group_17 {
  width: 890px;
}

.group_18 {
  text-align: center;
}

.font_7 {
  font-size: 28px;
  font-family: AlibabaPuHuiTiR;
  line-height: 40px;
  color: #000000;
}

.text_10 {
  margin-top: 32px;
  align-self: center;
  color: #000000;
}

.section_7 {
  margin-top: 4px;
  align-self: center;
  background-color: #000000;
  
  height: 1px;
}

.image_10 {
  margin-top: 49px;
  align-self: center;
  width: 536px;
  height: 600px;
}
.img1{
  width: 1200px;
  height: 400px;
}
.group_21{
  width:100%;
  background-color: #ebebeb;
  padding: 0 360px;
}
.text_11{
  font-size: 48px;
  text-align: center;
  margin-top: 180px;
  font-family: Albbr;
}
.text_12{
  font-size: 30px;
  text-align: center;
  margin-bottom: 70px;
  font-family: Albbr;
}
.group_22{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.group_23{
 margin-left: 46px;
}
.text_13{
   font-size: 42px;
   font-family: Albbr;
}
.text_14{
  font-size: 16px;
  font-family: Albbr;
}
.text_15{
  font-size: 16px;
  font-family: Albbr;
}
.img2{
  width: 1200px;
  height: 430px;
  margin-top:30px;
  margin-bottom:100px;
}
.text_16{
  font-size: 16px;
  border-bottom: 1px solid black;
  cursor: pointer;
  font-family: Albbr;
}
.group_24{
  text-align: center;
  margin-bottom:130px;
}
.group_25{
  width: 100%;
  height: 1077px;
  background-image: url(../assets/images/zz4.png) ;
  background-size: 100% 100%;
  position: relative;
  text-align: center;
}
.img3{
 width: 563px;
 height: 52px;
}
.group_26{
  margin-top: 80px;
  display: flex;
  flex-direction: column;
}
.group_27{
  text-align: center;
}
.group_28{
  text-align: center;
  margin-top: 30px;
}
.text_17{
  font-size: 28px;
  text-align: center;
  margin-top: 20px;
}
.text_18{
  font-size: 28px;
  text-align: center;
  margin-top: 10px;
}
.text_19{
  font-size: 18px;
  cursor: pointer;
  border-bottom: 1px solid black;
}
.group_20_1{
  width: 1200px;
}
</style>